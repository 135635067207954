import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/cocktails',
    name: 'Cocktails',
    component: () => import(/* webpackChunkName: "about" */ '../views/CocktailsView.vue'),
    meta: {
      title: 'Cocktails'
    }
  },
  {
    path: '/shooters',
    name: 'Shooters',
    component: () => import(/* webpackChunkName: "about" */ '../views/ShootersView.vue'),
    meta: {
      title: 'Shooters'
    }
  },
  {
    path: '/recipes',
    name: 'Recipes',
    component: () => import(/* webpackChunkName: "about" */ '../views/RecipesView.vue'),
    meta: {
      title: 'Rezepte'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
