<template>
  <div id="app">
    <app-header/>
    <b-container fluid style="height: 100%">
      <b-row class="main">
        <b-col cols="12">
          <router-view :key="$route.fullPath"/>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
const AppHeader = () => import(/* webpackChunkName: "header" */ './components/structure/AppHeader')

export default {
  name: 'App',
  components: {
    AppHeader
  }
}
</script>

<style lang="scss">
</style>
