import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from "axios";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import './assets/styles/App.scss'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false
axios.defaults.withCredentials = false
axios.defaults.baseURL = process.env.VUE_APP_API_FRONTEND
axios.defaults.headers.post = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}
axios.defaults.headers.put = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}
axios.defaults.headers.get = {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

new Vue({
  router,
  render: function (h) { return h(App) }
}).$mount('#app')
